<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Tipo de Planilhas" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              

            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" exportFilename="relatorio[TIPOSPLANILHA]" csvSeparator=";" :value="tipos_planilhas"
          v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
              <h5 class="m-0">Gerenciar Tipos de Planilha</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="razao_social" header="Fornecedor" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Fornecedor</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="tipo" header="Tipo" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title"></span>
              {{ slotProps.data.tipo }}
            </template>
          </Column>


          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarEmpresaModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Gerenciar Empresa" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="Status" class="mb-3">Fornecedor</label>
            <Dropdown id="empresa" v-model="tipo.id_fornecedor" :options="fornecedores" optionLabel="label"
              optionValue="value" placeholder="Selecione um fornecedor" :class="{ 'p-invalid': submitted && !tipo.id_fornecedor }">
              <small class="p-invalid" v-if="submitted && !tipo.id_fornecedor">Fornecedor é obrigatório.</small>
            </Dropdown>
          </div>
          
          <div class="field">
            <label for="email">Tipo</label>
            <InputText id="email" type="email" v-model.trim="tipo.tipo" required="true" autofocus
              :class="{ 'p-invalid': submitted && !tipo.tipo }" />
              <small class="p-invalid" v-if="submitted && !tipo.tipo">Tipo é obrigatório.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarFornecedor" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;display:block;" />
            <span style="display:block;" v-if="empresa">Apagar: <b>{{ tipo.tipo }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarEmpresa" />
          </template>
        </Dialog>

        
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      tipos_planilhas: [],
      tipo: [],
      fornecedores:[],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      empresa: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarEmpresa();
    this.listarFornecedor();
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    listarFornecedor() {
      this.axios
        .post("/fornecedores/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["razao_social"] };
            options.push(option);
          });
          this.fornecedores = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEmpresa() {
      this.axios
        .post("/tipos_planilhas/listar")
        .then((response) => {
          console.log(response);
          this.tipos_planilhas = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    trocarStatus() {
      this.empresa.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.tipo = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarFornecedor() {
      this.submitted = true;
      this.tipo = { ...this.tipo };
      console.log(this.tipo)
      if (this.tipo.id) {
        this.axios
          .post("/tipos_planilhas/editar", this.tipo)
          .then(() => {
            this.listarEmpresa();
            this.productDialog = false;
            this.empresa = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/tipos_planilhas/adicionar", this.tipo)
          .then(() => {
            this.listarEmpresa();
            this.productDialog = false;
            this.empresa = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(tipo) {
      this.productDialog = true;
      this.tipo = { ...tipo };

    },
    apagarEmpresaModal(empresa) {
      this.empresa = empresa;
      this.deleteProductDialog = true;
    },
    apagarEmpresa() {
      this.axios
        .post("/tipos_planilhas/apagar", this.empresa)
        .then(() => {
          this.listarEmpresa();
          this.deleteProductDialog = false;
          this.empresa = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Empresa apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {

      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {

        this.axios
          .post("/tipos_planilhas/apagar", obj[key])
          .then(() => {
            this.listarEmpresa();
            this.deleteProductDialog = false;
            this.empresa = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Empresa apagada com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Erro, chama o t.i",
              life: 3000,
            });
          });
      });


    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
